<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <template v-if="isEnrolledCounseling">
          <el-breadcrumb-item>
            <router-link
              :to="{
                name: 'counselingClassesDetail',
                params: { id: $route.params.id }
              }"
            >
              <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
              {{ sessionClass.title }}
            </router-link>
          </el-breadcrumb-item>
        </template>
        <template v-else>
          <el-breadcrumb-item>
            <router-link :to="{ name: 'counseling' }">
              Counseling
            </router-link>
          </el-breadcrumb-item>
        </template>
        <el-breadcrumb-item>
          <router-link :to="tasksPageRouter">
            {{ prevPageTitle }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div>
      <h1>{{ pageTitle }}</h1>
      <hr class="separate-line" />
      <SubtaskForm @onSubmit="createSubtask" @onGoToTasksPage="goToTasksPage" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import SubtaskForm from "@/views/private/Counseling/Tasks/Components/SubtaskForm";
import tasksApi from "@/apis/tasks";
import privateApi from "@/apis/private";
import profileApi from "@/apis/profile";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    IsOnlineIcon,
    SubtaskForm
  },
  data() {
    return {
      student: {},
      sessionClass: {
        course_session: {}
      }
    };
  },
  computed: {
    pageTitle() {
      return "Create Subtasks";
    },
    prevPageTitle() {
      if (this.student.first_name) {
        return `${this.student.first_name}'s Tasks`;
      } else {
        return "";
      }
    },
    studentUserId() {
      return this.student.id;
    },
    isEnrolledCounseling() {
      return this.$route.params.id;
    },
    tasksPageRouter() {
      if (this.isEnrolledCounseling) {
        return {
          name: "counselingTasks",
          params: { id: this.$route.params.id }
        };
      } else {
        return {
          name: "counselingUserTasks",
          params: { username: this.$route.params.username }
        };
      }
    }
  },
  async created() {
    await this.setLessonAndUser();
  },
  methods: {
    async setLessonAndUser() {
      if (this.isEnrolledCounseling) {
        await this.fetchCustomLesson();
      } else {
        await this.fetchUserProfile();
      }
    },
    async fetchCustomLesson() {
      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.id
      });
      this.student = customLesson.student_class_tickets[0].student;
      this.sessionClass = {
        ...customLesson.session_class
      };
    },
    async fetchUserProfile() {
      const {
        basic_info,
        student: { counseling_schools_type }
      } = await profileApi.getUserByName(this.$route.params.username);
      this.student = { ...basic_info, counseling_schools_type };
    },
    goToTasksPage() {
      this.$router.push(this.tasksPageRouter);
    },
    async createSubtask(subtask) {
      try {
        await tasksApi.createSubtask({
          ...subtask,
          user_id: this.studentUserId
        });
        this.goToTasksPage();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>
